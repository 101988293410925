
    import { createApp } from 'vue';
    import App from './App.vue';
    import router from './router';
    import AOS from 'aos';
    import 'aos/dist/aos.css';
    import i18n from './i18n';
    import axios from 'axios';
    import print from 'vue3-print-nb'

    import BootstrapVue3 from 'bootstrap-vue-3';
    import VueApexCharts from 'vue3-apexcharts';

    import Toast from 'vue-toastification'
    import 'vue-toastification/dist/index.css'
    import { useToast } from 'vue-toastification'

    import ElementPlus from 'element-plus';
    import 'element-plus/dist/index.css';

    import CKEditor from '@ckeditor/ckeditor5-vue';

    import '@/assets/scss/config/saas/app.scss';
    import '@vueform/slider/themes/default.css';
    import '@/assets/scss/mermaid.min.css';

    import { getSettings } from '@/helpers/setting';
    import { permissions } from '@/helpers/permission';

    // add token to request
    const token = localStorage.getItem('jwt');
    axios.defaults.headers.common['authorization'] = 'Bearer ' + token;

    // add currency to request
    const currency = localStorage.getItem('currency');
    axios.defaults.headers.common['currency'] = currency;
    const toast = useToast()

    axios.interceptors.response.use(null, function(error) {
      toast.error(error.response.data.message)
      return Promise.reject(error);
    });

    const options = {
        position: 'top-left',
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
      }
    // Initialize your app with permissions
    async function initializeApp() {
        try {


            // Get permissions and wait for it to be resolved
            const userPermissions = await permissions();

            // Set up the rest of your app based on permissions
            AOS.init({
                easing: 'ease-out-back',
                duration: 1000,
            });

            // You can handle user permissions here as needed
            // For example, you can store them in a global object
            window.userPermissions = userPermissions;

            getSettings()
            // prepare local via i18n
            const storedLang = localStorage.getItem('lang');
            if (storedLang) {
                const lang = JSON.parse(storedLang);
                i18n.global.locale = lang.lang;
                axios.defaults.headers.common['Accept-Language'] = lang.lang;
            } else {
                axios.defaults.headers.common['Accept-Language'] = 'en';
            }

            // Create the Vue app
            createApp(App)
                .use(router)
                .use(CKEditor)
                .use(VueApexCharts)
                .use(BootstrapVue3)
                .use(ElementPlus)
                .use(i18n)
                .use(print)
                .use(Toast, options)
                .mount('#app');

        } catch (error) {
            console.error('Error initializing the app:', error);
        }
    }


    initializeApp();
