export default [
  {
      path: "/pages",
      name: "pages",
      meta: {title: "pages", authRequired: true},
      component: () => import("../views/pages/index"),
  },
  {
      path: "/pages/privacy",
      name: "Privacy",
      meta: {title: "privacy", authRequired: true},
      component: () => import("../views/pages/privacy/form"),
  },
  {
      path: "/pages/about",
      name: "About",
      meta: {title: "about", authRequired: true},
      component: () => import("../views/pages/about/form"),
  },
  {
      path: "/pages/terms",
      name: "Terms",
      meta: {title: "terms", authRequired: true},
      component: () => import("../views/pages/terms/form"),
  },
  {
      path: "/pages/return",
      name: "Return",
      meta: {title: "return", authRequired: true},
      component: () => import("../views/pages/return/form"),
  },
  {
      path: "/pages/contact",
      name: "Contact",
      meta: {title: "contact", authRequired: true},
      component: () => import("../views/pages/contact/form"),
  },
]