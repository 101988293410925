export default [

  {
    path: "/blogs",
    name: "blogs",
    meta: { title: "blogs", authRequired: true },
    component: () => import("../views/blogs/index"),
  },
  {
    path: "/blogs/add",
    name: "blogs add",
    meta: { title: "blogs add", authRequired: true },
    component: () => import("../views/blogs/form"),
  }
  ,
  {
    path: "/blogs/edit/:id",
    name: "blogs edit",
    meta: { title: "blogs edit", authRequired: true },
    component: () => import("../views/blogs/form"),
  },

  {
    path: "/banners",
    name: "banners",
    meta: { title: "banners", authRequired: true },
    component: () => import("../views/banners/index"),
  },
  {
    path: "/banners/add",
    name: "banners add",
    meta: { title: "banners add", authRequired: true },
    component: () => import("../views/banners/form"),
  }
  ,
  {
    path: "/banners/edit/:id",
    name: "banners edit",
    meta: { title: "banners edit", authRequired: true },
    component: () => import("../views/banners/form"),
  },

  {
    path: "/footer-links",
    name: "footer-links",
    meta: { title: "footer-links", authRequired: true },
    component: () => import("../views/footerLinks/index"),
  },
  {
    path: "/footer-links/add",
    name: "footer-links add",
    meta: { title: "footer-links add", authRequired: true },
    component: () => import("../views/footerLinks/form"),
  }
  ,
  {
    path: "/footer-links/edit/:id",
    name: "footer-links edit",
    meta: { title: "footer-links edit", authRequired: true },
    component: () => import("../views/footerLinks/form"),
  },

  {
    path: "/footer-categories",
    name: "footer-categories",
    meta: { title: "footer-categories", authRequired: true },
    component: () => import("../views/footerCategories/index"),
  },
  {
    path: "/footer-categories/add",
    name: "footer-categories add",
    meta: { title: "footer-categories add", authRequired: true },
    component: () => import("../views/footerCategories/form"),
  }
  ,
  {
    path: "/footer-categories/edit/:id",
    name: "footer-categories edit",
    meta: { title: "footer-categories edit", authRequired: true },
    component: () => import("../views/footerCategories/form"),
  },

  {
    path: "/userGroups",
    name: "userGroups",
    meta: { title: "user groups", authRequired: true },
    component: () => import("../views/userGroups/index"),
  },
  {
    path: "/userGroups/add",
    name: "userGroups add",
    meta: { title: "user groups add", authRequired: true },
    component: () => import("../views/userGroups/form"),
  }
  ,
  {
    path: "/userGroups/edit/:id",
    name: "userGroups edit",
    meta: { title: "user groups edit", authRequired: true },
    component: () => import("../views/userGroups/form"),
  }

  ,
  {
    path: "/sections",
    name: "sections",
    meta: { title: "Sections", authRequired: true },
    component: () => import("../views/sections/index"),
  }
  ,
  {
    path: "/sections/add",
    name: "sections add",
    meta: { title: "sections add", authRequired: true },
    component: () => import("../views/sections/form"),
  }
  ,
  {
    path: "/sections/edit/:id",
    name: "sections edit",
    meta: { title: "sections edit", authRequired: true },
    component: () => import("../views/sections/form"),
  }
  ,
  {
    path: "/sliders",
    name: "sliders",
    meta: { title: "sliders", authRequired: true },
    component: () => import("../views/sliders/index"),
  },
  {
    path: "/sliders/add",
    name: "sliders add",
    meta: { title: "sliders add", authRequired: true },
    component: () => import("../views/sliders/form"),
  },
  {
    path: "/sliders/edit/:id",
    name: "sliders edit",
    meta: { title: "sliders edit", authRequired: true },
    component: () => import("../views/sliders/form"),
  },



  {
    path: "/malfunctions",
    name: "malfunctions",
    meta: { title: "malfunctions", authRequired: true },
    component: () => import("../views/malfunctions/index"),
  },

  {
    path: "/malfunctions/add",
    name: "malfunctions add",
    meta: { title: "malfunctions", authRequired: true },
    component: () => import("../views/malfunctions/form"),
  },
  {
    path: "/malfunctions/edit/:id",
    name: "malfunctions edit",
    meta: { title: "malfunctions edit", authRequired: true },
    component: () => import("../views/malfunctions/form"),
  },

  {
    path: "/services",
    name: "services",
    meta: { title: "services", authRequired: true },
    component: () => import("../views/maintenance_services/index"),
  },

  {
    path: "/services/add",
    name: "services add",
    meta: { title: "services", authRequired: true },
    component: () => import("../views/maintenance_services/form"),
  },
  {
    path: "/services/edit/:id",
    name: "services edit",
    meta: { title: "services edit", authRequired: true },
    component: () => import("../views/maintenance_services/form"),
  },

  {
    path: "/maintenance-requests",
    name: "maintenance-requests",
    meta: {title: "maintenance-requests", authRequired: true},
    component: () => import("../views/maintenance-requests/index"),
  },

  {
    path: "/maintenance-requests/show/:id",
    name: "maintenance-requests show",
    meta: {title: "maintenance-request", authRequired: true},
    component: () => import("../views/maintenance-requests/show"),
  },


  {
    path: "/recovery-requests",
    name: "recovery-requests",
    meta: {title: "recovery-requests", authRequired: true},
    component: () => import("../views/recovery-requests/index"),
  },

  {
    path: "/recovery-requests/show/:id",
    name: "recovery-requests show",
    meta: {title: "recovery-request", authRequired: true},
    component: () => import("../views/recovery-requests/show"),
  },


  {
    path: "/media-types",
    name: "media-types",
    meta: { title: "media-types", authRequired: true },
    component: () => import("../views/media-types/index"),
  },

  {
    path: "/media-types/add",
    name: "media-types add",
    meta: { title: "media-types", authRequired: true },
    component: () => import("../views/media-types/form"),
  },
  {
    path: "/media-types/edit/:id",
    name: "media-types edit",
    meta: { title: "media-types edit", authRequired: true },
    component: () => import("../views/media-types/form"),
  },

  {
    path: "/failure-reasons",
    name: "failure-reasons",
    meta: { title: "failure-reasons", authRequired: true },
    component: () => import("../views/failure-reasons/index"),
  },

  {
    path: "/failure-reasons/add",
    name: "failure-reasons add",
    meta: { title: "failure-reasons", authRequired: true },
    component: () => import("../views/failure-reasons/form"),
  },
  {
    path: "/failure-reasons/edit/:id",
    name: "failure-reasons edit",
    meta: { title: "failure-reasons edit", authRequired: true },
    component: () => import("../views/failure-reasons/form"),
  },

  {
    path: "/templates",
    name: "templates",
    meta: { title: "templates", authRequired: true },
    component: () => import("../views/templates/index"),
  },

  {
    path: "/templates/add",
    name: "templates add",
    meta: { title: "templates", authRequired: true },
    component: () => import("../views/templates/form"),
  },
  {
    path: "/templates/edit/:id",
    name: "templates edit",
    meta: { title: "templates edit", authRequired: true },
    component: () => import("../views/templates/form"),
  },

  {
    path: "/blog-categories",
    name: "blog-categories",
    meta: {title: "blog-categories", authRequired: true},
    component: () => import("../views/blog-categories/index"),
  },


    {
        path: "/blog-categories/add",
        name: "blog-categories add",
        meta: {title: "blog-categories add", authRequired: true},
        component: () => import("../views/blog-categories/form"),
    }
    ,
    {
      path: "/blog-categories/edit/:id",
      name: "blog-categories edit",
      meta: {title: "blog-categories edit", authRequired: true},
      component: () => import("../views/blog-categories/form"),
  },
  {
    path: "/blog-categories/edit/:id",
    name: "blog-categories edit",
    meta: {title: "blog-categories edit", authRequired: true},
    component: () => import("../views/blog-categories/form"),
  },
  {
    path: "/profile/",
    name: "edit-profile",
    meta: {title: "Profile", authRequired: true},
    component: () => import("../views/profile"),
  },
  {
    path: "/transactions/",
    name: "transactions",
    meta: {title: "Transactions", authRequired: true},
    component: () => import("../views/transactions"),
  },
  {
    path: "/send/notifications/mail",
    name: "send-notifications-mail",
    meta: {title: "Send Notifications Mail", authRequired: true},
    component: () => import("../views/send/send_notification_mail"),
  },
  {
    path: "/send/notifications/mail/log",
    name: "send-notifications-mail-log",
    meta: {title: "Send Notifications Mail Log", authRequired: true},
    component: () => import("../views/send/index"),
  },
  {
    path: "/product-report/",
    name: "product-report",
    meta: {title: "Products Report", authRequired: true},
    component: () => import("../views/reports/products_report"),
  },
  {
    path: "/product-report-option/:id",
    name: "product-report-option",
    meta: {title: "Product Report Option", authRequired: true},
    component: () => import("../views/reports/product_report_option"),
  },
  {
    path: "/order-report/",
    name: "order-report",
    meta: {title: "Orders Report", authRequired: true},
    component: () => import("../views/reports/orders_report"),
  },
  {
    path: "/discount-code-report/",
    name: "discount-code-report",
    meta: {title: "Discount Codes Report", authRequired: true},
    component: () => import("../views/reports/discount_codes_report"),
  },

  {
    path: "/order-requests",
    name: "order-requests",
    meta: {title: "Order Requests", authRequired: true},
    component: () => import("../views/order-requests/index"),
  },
  {
    path: "/order-requests/show/:id",
    name: "order-requests-show",
    meta: {title: "Order Requests show", authRequired: true},
    component: () => import("../views/order-requests/show"),
  },
  {
    path: "/products/stock",
    name: "products-stock",
    meta: {title: "Products stock", authRequired: true},
    component: () => import("../views/products/stock/index"),
  },
  {
    path: "/products/stock/:id",
    name: "products-stock-edit",
    meta: {title: "Products stock edit", authRequired: true},
    component: () => import("../views/products/stock/edit"),
  },

]