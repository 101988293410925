import axios from "axios";
import linkGenrator from "@/helpers/linkGenrator";


export const permissions = async () => {
    try {
        const response = await axios.get(linkGenrator('user/permissions'));
        return response.data;
    } catch (error) {
        // Handle any errors that may occur during the axios request
        console.error("Error fetching permissions:", error);
        return [];
    }
};



export const checkPermission =  (permission)=>
{
    const permissions =  window.userPermissions;
    if(permission === 'dashboard'){
        return true
    }
    if(typeof permission === 'object'){
        let arr = []
        permission.map((q)=>{
            if(permissions.includes(q)){
                arr.push(true)
            }
        })
        if(arr.includes(true)){
            return true
        }
    }else{
        if(permissions.includes(permission)){
            return true
        }
    }

    return false
}